import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import vector2 from "../assets/Vector2.png";
import vector3 from "../assets/Vector3.png";
import vector4 from "../assets/Vector4.png";
import { FaUser } from "react-icons/fa";

import { useQuery } from "react-query";
import axios from "axios";
import { useAuth } from "../context/auth";
import { Button, Card, Menu, MenuItem } from "@mui/material";
import ClampLines from "react-clamp-lines";
import unsplash4 from "../assets/unsplash4.png";
import unsplash5 from "../assets/unsplash5.png";
import { LanguageContext } from "../context/LanguageContext";
import ReactPlayer from "react-player";
import { ThreeCircles } from "react-loader-spinner";
import { BiLogOut } from "react-icons/bi";
import { Box, FormControl, InputLabel, Select, Fade } from "@mui/material";
import { FaBars } from "react-icons/fa";
import { BiPlay } from "react-icons/bi";
import { MdOutlineQuiz } from "react-icons/md";
import Modal from "@mui/material/Modal";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.bubble.css";
import { CategoryContext } from "../context/CategoryContext";
const style = {
  position: "absolute",
  top: "20%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const NavBar = () => {
  const navigate = useNavigate();
  const {
    isEnglishLang,
    setIsEnglishLang,
    isAmharicLang,
    setIsAmharicLang,
    isOromoLang,
    setIsOromoLang,
  } = useContext(LanguageContext);

  const { category, setCategory } = useContext(CategoryContext);
  const [isScholarship, setIsScolarship] = useState(true);
  const [isAmharic, setIsAmharic] = useState(false);

  const [isEnglish, setIsEnglish] = useState(false);
  const [isChinese, setIsChinese] = useState(false);
  const [next, setNext] = useState(false);
  const [prev, setPrev] = useState(true);
  const [after, setAfter] = useState(false);
  const [details, setDetails] = useState(null);
  const { token, user, logout } = useAuth();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openBars, setOpenBars] = useState(false);
  const handleOpenBars = () => setOpenBars(true);
  const handleCloseBars = () => setOpenBars(false);

  const [anchorElProfile, setAnchorElProfile] = React.useState(null);
  const openProfile = Boolean(anchorElProfile);
  const handleClickProfile = (event) => {
    setAnchorElProfile(event.currentTarget);
  };
  const handleCloseProfile = () => {
    setAnchorElProfile(null);
  };

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

 

  const CategoryData = useQuery(
    ["CategoryDataApi", category],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}homepage?categories`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        setCategory(null);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
        }
      },
    }
  );

  return (
    <div>
      {" "}
      <div className="flex flex-col lg:h-[15%] bg-gray-100">
        <div className="flex flex-row lg:ml-[4%] ">
          <div>
            <button onClick={() => navigate("/")}>
              <img
                className=" w-[90%] my-[4%] lg:m-[1%] ml-[6%] lg:ml-]|[0%] mt-[16%] lg:mt-[40%] "
                alt="Vector"
                src={vector2}
              />
            </button>
          </div>
          <div className="pl-[0%] pt-[8%] flex flex-col lg:pt-[3%] lg:pl-[0%] lg:w-[20%]">
            <p className="font-inter  font-bold xs:text-4xl sm:text-xl md:text-xl lg:text-xl">
              <button onClick={() => navigate("/")}>ClassABCD</button>
            </p>
            <p className="hidden lg:flex font-inter xs:text-4xl sm:text-xl md:text-xl lg:text-xl  text-[#f97316] ">
              <button onClick={() => navigate("/")}>Learning Center</button>
            </p>
          </div>
          <div className=" hidden w-[0%] h-[0%]  lg:w-[60%]  lg:block  lg:px-[0%] lg:py-[0%] lg:my-[4%]">
            <div className="bg-opacity-50  rounded-lg bg-clip-padding">
              <div className=" flex flex-row justify-start  px-[1%] py-[0%] my-[0%] lg:text-base">
                <div className="flex flex-row w-[30%]">
                  {CategoryData?.data?.data?.categories?.map((item, i) => {
                    return i < 3 ? (
                      <>
                        <div key={i}>
                          {" "}
                          <MenuItem
                            onClick={() => {
                              setCategory(1);
                              navigate(`/category/${item.id}`);
                            }}
                          >
                            {" "}
                            {isEnglishLang ? (
                              <>
                                {i != 0 ? (
                                  <>
                                    {" "}
                                    <p className="text-xl pr-4">|</p>
                                    <p className="text-sm font-bold">
                                      {item.title.english}
                                    </p>
                                  </>
                                ) : (
                                  <p className="text-sm pt-1 font-bold">
                                    {item.title.english}
                                  </p>
                                )}
                              </>
                            ) : isAmharicLang ? (
                              <>
                                {i != 0 ? (
                                  <>
                                    {" "}
                                    <p className="text-xl pr-4">|</p>
                                    <p className="text-sm font-bold">
                                      {item.title.amharic}
                                    </p>
                                  </>
                                ) : (
                                  <p className="text-sm pt-1 font-bold">
                                    {item.title.amharic}
                                  </p>
                                )}
                              </>
                            ) : (
                              <>
                                {i != 0 ? (
                                  <>
                                    {" "}
                                    <p className="text-xl pr-4">|</p>
                                    <p className="text-sm font-bold">
                                      {item.title.oromiffa}
                                    </p>
                                  </>
                                ) : (
                                  <p className="text-sm pt-1 font-bold">
                                    {item.title.oromiffa}
                                  </p>
                                )}
                              </>
                            )}
                          </MenuItem>
                        </div>
                      </>
                    ) : null;
                  })}
                  <div className="flex flex-row w-[30%]">
                    {CategoryData?.data?.data?.categories?.[3] ? (
                      <Box sx={{ minWidth: 10 }}>
                        <FormControl fullWidth>
                          <InputLabel
                            sx={{
                              fontSize: 10,
                              fontFamily: "Inter",
                              color: "black",
                              pb: 2,
                            }}
                            id="demo-simple-select-label"
                          ></InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            sx={{
                              boxShadow: "none",
                              ".MuiOutlinedInput-notchedOutline": {
                                border: 0,
                              },
                              marginTop: 0,
                              width: "100%",
                              height: "80%",
                            }}
                            label="Age"
                          >
                            {CategoryData?.data?.data?.categories?.map(
                              (item, i) => {
                                return i > 2 ? (
                                  <>
                                    <div key={i}>
                                      <div className="    text-xl mb-[1%]">
                                        <MenuItem
                                          onClick={() => {
                                            setCategory(1);
                                            navigate(`/category/${item.id}`);
                                          }}
                                        >
                                          {" "}
                                          {isEnglishLang ? (
                                            <>
                                              {" "}
                                              <p className="text-sm font-bold">
                                                {item.title.english}
                                              </p>
                                            </>
                                          ) : isAmharicLang ? (
                                            <>
                                              {" "}
                                              <p className="text-sm font-bold">
                                                {item.title.amharic}
                                              </p>
                                            </>
                                          ) : (
                                            <>
                                              {" "}
                                              <p className="text-sm font-bold">
                                                {item.title.oromiffa}
                                              </p>
                                            </>
                                          )}
                                        </MenuItem>
                                      </div>
                                    </div>
                                  </>
                                ) : null;
                              }
                            )}
                          </Select>
                        </FormControl>
                      </Box>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:hidden flex pt-[0%] w-[50%] justify-end text-4xl text-[#f97316]">
            <Button
              sx={{ color: "#f97316", fontSize: "45px" }}
              onClick={handleOpenBars}
            >
              <FaBars />
            </Button>
            <Modal
              open={openBars}
              onClose={handleCloseBars}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div className=" flex justify-center lg:hidden flex-row">
                  <button
                    className=" ml-[0%] lg:ml-[1%]   px-[0%] py-[2%] lg:px-[2%] mr-[10%] lg:my-[4%] rounded-lg "
                    onClick={() => navigate("/exams")}
                  >
                    <div className="text-3xl flex  justify-center">
                      <MdOutlineQuiz />
                    </div>
                    <p className="text-xs flex font-bold  justify-center">
                      {" "}
                      {isEnglishLang ? (
                        <>Exams </>
                      ) : isAmharicLang ? (
                        <>ፈተናዎች </>
                      ) : (
                        <>Qormaata </>
                      )}
                    </p>
                  </button>
                  <button
                    className=" ml-[0%] lg:ml-[1%]   px-[0%] py-[2%] lg:px-[2%] my-[0%] lg:my-[4%] rounded-lg "
                    onClick={() => navigate("/search")}
                  >
                    <img className="w-[100%]  " alt="Vector" src={vector3} />
                  </button>
                  <p className="flex mt-[0%] text-4xl pl-2">|</p>

                  <div className=" mt-[0%] ml-[0%] lg:mt-[3%] mx-[0%]  lg:mx-[2%]  ">
                    <Button
                      variant="text"
                      style={{
                        color: "black",
                        borderRadius: 64,

                        padding: "16px ",
                        margin: "0px",
                      }}
                      aria-controls={openProfile ? "fade-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openProfile ? "true" : undefined}
                      onClick={handleClickProfile}
                    >
                      <FaUser className=" text-2xl" />
                    </Button>

                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                        "aria-labelledby": "fade-button",
                      }}
                      anchorEl={anchorElProfile}
                      open={openProfile}
                      onClose={handleCloseProfile}
                      TransitionComponent={Fade}
                    >
                      <MenuItem onClick={() => navigate("/")}>Profile</MenuItem>

                      <MenuItem onClick={logout}>Logout</MenuItem>
                    </Menu>
                  </div>

                  <p className="flex mt-[0%] text-4xl px-0">|</p>

                  <div className="mx-[0%]">
                    <Button
                      className=" ml-[2%]  px-[4%]  my-[4%] rounded-lg flex flex-col"
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      <img alt="Vector" src={vector4} />
                      <p className="text-[#f97316] text-center text-xs "> {isEnglishLang ? (
                      <>
                      {" "}ቋንቋ/Afaan
                    </>
                    ) : isAmharicLang ? (
                      <>
                      {" "}Lang/Afaan
                    </>
                    ) : (
                      <>
                      {" "}Lang/ቋንቋ
                   </>
                    )} </p>
                    </Button>
                    <Menu
                      id="basic-menu"
                      sx={{
                        left: { xs: "0%", lg: "0%" },
                        top: { xs: "0%", lg: "-10%" },
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          setIsEnglishLang(true);
                          setIsAmharicLang(false);
                          setIsOromoLang(false);
                        }}
                      >
                        English
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          setIsEnglishLang(false);
                          setIsAmharicLang(true);
                          setIsOromoLang(true);
                        }}
                      >
                        አማርኛ
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          handleClose();
                          setIsEnglishLang(false);
                          setIsAmharicLang(false);
                          setIsOromoLang(true);
                        }}
                      >
                        Oromiffa
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
          <button
            className="hidden lg:flex ml-[6%] lg:ml-[1%]   py-[0.5%] lg:px-[1%] mr-[5%] lg:mt-11 h-[60%]  rounded-lg "
            onClick={() => navigate("/exams")}
          >
            <div className="text-xl flex  justify-center">
              <MdOutlineQuiz />
            </div>
            <p className="text-base flex pt-[0%] pl-2 font-bold justify-center">
              {" "}
              {isEnglishLang ? (
                <>Exams </>
              ) : isAmharicLang ? (
                <>ፈተናዎች </>
              ) : (
                <>Qormaata </>
              )}
            </p>
          </button>
          <div className="hidden lg:flex mt-[%] lg:mt-[4%] h-[60%] lg:mr-[1%]    ">
            <button
              className="  lg:flex w-4 justify-center px-[] pt-[] py-[] rounded-lg flex-row "
              onClick={() => navigate("/search")}
            >
              <img className="w-[100%]" alt="Vector" src={vector3} />
            </button>
          </div>
          <p className="hidden lg:flex lg:mt-[3.2%] text-2xl px-2">|</p>
          <div className="hidden lg:flex mt-[0%] lg:mt-[3.4%] h-[40%] mx-[1%]  lg:mx-[0%]  ">
            <Button
              variant="text"
              style={{
                color: "black",
                borderRadius: 64,

                padding: "6px ",
                margin: "0px",
              }}
              aria-controls={openProfile ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openProfile ? "true" : undefined}
              onClick={handleClickProfile}
            >
              <FaUser className=" text-xl" />
            </Button>

            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorElProfile}
              open={openProfile}
              onClose={handleCloseProfile}
              TransitionComponent={Fade}
            >
              {/*               <MenuItem onClick={() => navigate("/")}>Profile</MenuItem>
               */}
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
          </div>
          <p className="hidden lg:flex lg:mt-[3.2%] text-2xl pr-4">|</p>
          <div className=" hidden lg:flex  ">
            <button
              className="  flex-col mt-[75%] mr-4"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <img className="w-[40%] ml-[25%]" alt="Vector" src={vector4} />
              <p className="text-[#f97316] text-xs lowercase ">{isEnglishLang ? (
                      <>
                      {" "} ቋንቋ/Afaan
                    </>
                    ) : isAmharicLang ? (
                      <>
                      {" "}Lang/Afaan
                    </>
                    ) : (
                      <>
                      {" "}Lang/ቋንቋ
                   </>
                    )} </p>
            </button>
            <Menu
              id="basic-menu"
              sx={{
                left: { xs: "0%", lg: "0%" },
                top: { xs: "0%", lg: "-10%" },
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  setIsEnglishLang(true);
                  setIsAmharicLang(false);
                  setIsOromoLang(false);
                }}
              >
                English
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setIsEnglishLang(false);
                  setIsAmharicLang(true);
                  setIsOromoLang(true);
                }}
              >
                አማርኛ
              </MenuItem>

              <MenuItem
                onClick={() => {
                  handleClose();
                  setIsEnglishLang(false);
                  setIsAmharicLang(false);
                  setIsOromoLang(true);
                }}
              >
                Oromiffa
              </MenuItem>
            </Menu>
          </div>
        </div>

        <div className=" lg:hidden lg:w-[100%]  px-[0%] py-[2%] my-[2%]">
          <div className=" bg-opacity-50  rounded-lg bg-clip-padding">
            <div className=" flex flex-row justify-start  px-[5%] py-[0%] my-[0%] lg:text-base">
              <div className="flex flex-row w-[30%]">
                <div className="flex flex-col">
                  <div className="flex flex-row">
                    {CategoryData?.data?.data?.categories?.map((item, i) => {
                      return i < 3 ? (
                        <>
                          <div key={i}>
                            {" "}
                            <MenuItem
                              onClick={() => {
                                setCategory(1);
                                navigate(`/category/${item.id}`);
                              }}
                            >
                              {" "}
                              {isEnglishLang ? (
                                <>
                                  {i != 0 ? (
                                    <>
                                      {" "}
                                      <p className="text-xl pr-4">|</p>
                                      <p className="text-sm font-bold">
                                        {item.title.english}
                                      </p>
                                    </>
                                  ) : (
                                    <p className="text-sm font-bold">
                                      {item.title.english}
                                    </p>
                                  )}
                                </>
                              ) : isAmharicLang ? (
                                <>
                                  {i != 0 ? (
                                    <>
                                      {" "}
                                      <p className="text-xl pr-4">|</p>
                                      <p className="text-sm font-bold">
                                        {item.title.amharic}
                                      </p>
                                    </>
                                  ) : (
                                    <p className="text-sm font-bold">
                                      {item.title.amharic}
                                    </p>
                                  )}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  {i != 0 ? (
                                    <>
                                      {" "}
                                      <p className="text-xl pr-4">|</p>
                                      <p className="text-sm font-bold">
                                        {item.title.oromiffa}
                                      </p>
                                    </>
                                  ) : (
                                    <p className="text-sm font-bold">
                                      {item.title.oromiffa}
                                    </p>
                                  )}
                                </>
                              )}
                            </MenuItem>
                          </div>
                        </>
                      ) : null;
                    })}
                  </div>
                  <div className="flex flex-col">
                    {CategoryData?.data?.data?.categories?.map((item, i) => {
                      return i > 2 && i < 6 ? (
                        <>
                          <div key={i}>
                            {" "}
                            <MenuItem
                              onClick={() => {
                                setCategory(1);
                                navigate(`/category/${item.id}`);
                              }}
                            >
                              {" "}
                              {isEnglishLang ? (
                                <>
                                  {i != 3 ? (
                                    <>
                                      {" "}
                                      <p className="text-xl pr-4">|</p>
                                      <p className="text-sm font-bold">
                                        {item.title.english}
                                      </p>
                                    </>
                                  ) : (
                                    <p className="text-sm font-bold">
                                      {item.title.english}
                                    </p>
                                  )}
                                </>
                              ) : isAmharicLang ? (
                                <>
                                  {i != 3 ? (
                                    <>
                                      {" "}
                                      <p className="text-xl pr-4">|</p>
                                      <p className="text-sm font-bold">
                                        {item.title.amharic}
                                      </p>
                                    </>
                                  ) : (
                                    <p className="text-sm font-bold">
                                      {item.title.amharic}
                                    </p>
                                  )}
                                </>
                              ) : (
                                <>
                                  {i != 3 ? (
                                    <>
                                      {" "}
                                      <p className="text-xl pr-4">|</p>
                                      <p className="text-sm font-bold">
                                        {item.title.oromiffa}
                                      </p>
                                    </>
                                  ) : (
                                    <p className="text-sm font-bold">
                                      {item.title.oromiffa}
                                    </p>
                                  )}
                                </>
                              )}
                            </MenuItem>
                          </div>
                        </>
                      ) : null;
                    })}
                  </div>
                </div>
                <div className="flex flex-row w-[30%]">
                  {CategoryData?.data?.data?.categories?.[6] ? (
                    <Box sx={{ minWidth: 10 }}>
                      <FormControl fullWidth>
                        <InputLabel
                          sx={{
                            fontSize: 10,
                            fontFamily: "Inter",
                            color: "black",
                            pb: 2,
                          }}
                          id="demo-simple-select-label"
                        ></InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": {
                              border: 0,
                            },
                            marginTop: 0,
                            width: "100%",
                            height: "80%",
                          }}
                          label="Age"
                        >
                          {CategoryData?.data?.data?.categories?.map(
                            (item, i) => {
                              return i > 5 ? (
                                <>
                                  <div key={i}>
                                    <div className="    text-xl mb-[1%]">
                                      <MenuItem
                                        onClick={() => {
                                          setCategory(1);
                                          navigate(`/category/${item.id}`);
                                        }}
                                      >
                                        {" "}
                                        {isEnglishLang ? (
                                          <>
                                            {" "}
                                            <p className="text-sm font-bold">
                                              {item.title.english}
                                            </p>
                                          </>
                                        ) : isAmharicLang ? (
                                          <>
                                            {" "}
                                            <p className="text-sm font-bold">
                                              {item.title.amharic}
                                            </p>
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            <p className="text-sm font-bold">
                                              {item.title.oromiffa}
                                            </p>
                                          </>
                                        )}
                                      </MenuItem>
                                    </div>
                                  </div>
                                </>
                              ) : null;
                            }
                          )}
                        </Select>
                      </FormControl>
                    </Box>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
