import React, { useContext, useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import ReactQuill from "react-quill";

import { useNavigate } from "react-router-dom";

import { useQuery } from "react-query";
import axios from "axios";
import { useAuth } from "../../context/auth";
import { LanguageContext } from "../../context/LanguageContext";
import moment from "moment";

const RelatedExams = ({ id, setRelated }) => {
  const navigate = useNavigate();
  const {
    isEnglishLang,
    setIsEnglishLang,
    isAmharicLang,
    setIsAmharicLang,
    isOromoLang,
    setIsOromoLang,
  } = useContext(LanguageContext);
  const { token, user, logout } = useAuth();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const ExamData = useQuery(
    ["ExamDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}exams/${id}`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res);
        setRelated(null);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          logout();
        }
      },
    }
  );

  console.log(ExamData);
  return (
    <div>
      <p className="font-bold text-2xl ">
        <> Related</>
      </p>
      <div className=" ">
        <div>
          {" "}
          {ExamData?.isFetching ? (
            <>
              {" "}
              <div className="h-[100vh]  flex items-start justify-center min-h-0">
                <div ClassName="">
                  <ThreeCircles
                    height="50"
                    width="100"
                    color="#f97316"
                    ariaLabel="ThreeCircles-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {ExamData?.data?.data?.related?.map((item, i) => {
                return i < 4 ? (
                  <>
                    <div key={item}>
                      <div className="flex flex-col bg-white  p-1 mt-4 mr-4  rounded lg">
                        <div>
                          <div className="flex flex-col shadow-lg p-2">
                            <div className="flex flex-row">
                              {" "}
                              <div
                                className="  w-[24%] h-[100%]
                              "
                              >
                                <img
                                  className=" object-cover h-20 w-full rounded-lg  "
                                  alt="unsplash"
                                  src={item?.file?.link}
                                />
                              </div>
                              <div className="flex flex-col  w-[70%]">
                                <div className="  text-sm text-left lg:text-xs ">
                                  {isEnglishLang ? (
                                    <>
                                      <button
                                        onClick={() => {
                                          navigate(`/exam-info/${item.id}`);
                                          setRelated(1);
                                        }}
                                      >
                                        <p className="text-left pt-0 pl-4 text-base lg:font-bold">
                                          {item.subcategory.title.english}
                                        </p>
                                        <p className="lg:text-xs text-left lg:ml-[5%]">
                                          {" "}
                                          {moment(item.created_at).calendar()}
                                        </p>

                                        <ReactQuill
                                          className="max-h-12 overflow-hidden hover:underline"
                                          value={item.description}
                                          readOnly={true}
                                          theme={"bubble"}
                                        />
                                      </button>{" "}
                                    </>
                                  ) : isAmharicLang ? (
                                    <>
                                      <button
                                        onClick={() => {
                                          navigate(`/exam-info/${item.id}`);
                                          setRelated(1);
                                        }}
                                      >
                                        <p className="  lg:font-bold">
                                          {item.subcategory.title.english}
                                        </p>
                                        <ReactQuill
                                          className="max-h-12 overflow-hidden hover:underline"
                                          value={item.description}
                                          readOnly={true}
                                          theme={"bubble"}
                                        />
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        onClick={() => {
                                          navigate(`/exam-info/${item.id}`);
                                          setRelated(1);
                                        }}
                                      >
                                        <p className="  lg:font-bold">
                                          {item.subcategory.title.english}
                                        </p>
                                        <ReactQuill
                                          className="max-h-12 overflow-hidden hover:underline"
                                          value={item.description}
                                          readOnly={true}
                                          theme={"bubble"}
                                        />
                                      </button>
                                    </>
                                  )}
                                  <br />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null;
              })}{" "}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RelatedExams;
