import React, { useContext, useState } from "react";

import vector2 from "../../assets/Vector2.png";
import vector3 from "../../assets/Vector3.png";
import vector4 from "../../assets/Vector4.png";
import { useNavigate, useParams } from "react-router-dom";

import Footer from "../../components/Footer";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useQuery } from "react-query";
import axios from "axios";
import { useAuth } from "../../context/auth";
import ClampLines from "react-clamp-lines";
import { LanguageContext } from "../../context/LanguageContext";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  Typography,
} from "@mui/material";
import { ThreeCircles } from "react-loader-spinner";
import { BiHistory, BiLogOut } from "react-icons/bi";
import { MdOutlineQuiz } from "react-icons/md";
import { FaBars } from "react-icons/fa";
import Modal from "@mui/material/Modal";
import MultiClamp from "react-multi-clamp";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import ExamDetails from "./ExamDetals";
import unsplash from "../../assets/unsplash.png";
import moment from "moment";

const style = {
  position: "absolute",
  top: "20%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const styleError = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,

  p: 4,
};

const History = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    isEnglishLang,
    setIsEnglishLang,
    isAmharicLang,
    setIsAmharicLang,
    isOromoLang,
    setIsOromoLang,
  } = useContext(LanguageContext);

  const [next, setNext] = useState(false);
  const [prev, setPrev] = useState(true);

  const [counter, setCounter] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { token, user, logout } = useAuth();

  const [openBars, setOpenBars] = useState(false);
  const handleOpenBars = () => setOpenBars(true);
  const handleCloseBars = () => setOpenBars(false);

  const [openError, setOpenError] = React.useState(false);
  const handleOpenError = () => setOpenError(true);
  const handleCloseError = () => setOpenError(false);

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const ExamsHistoryData = useQuery(
    ["ExamsHistoryDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}exams-history`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          logout();
        }
      },
    }
  );

  return (
    <div>
      <div className="w-[100%] h-[100%]  overflow-hidden">
        <div className="">
          <div className="flex font-inter bg-gray-100 justify-center">
            <div className="flex flex-col w-[90%] ">
              <p className="text-center text-2xl mb-4 font-semibold">
                Exams History{" "}
              </p>

              <div className="grid  lg:grid-cols-4 gap-20 bg-white py-4 ">
                {ExamsHistoryData?.data?.data?.map((item) => {
                  return (
                    <div key={item}>
                      <div className=" shadow-lg py2 ">
                        <div className="  pl-6  text-left  text-xl mb-[1%]">
                          {" "}
                          <div ClassName=" w-[24%] h-[100%]">
                            <img
                              className="object-cover h-60 w-full rounded-lg"
                              alt="cover"
                              src={item?.exam?.file?.link}
                            />
                          </div>
                          <p className="text-xs">
                            {" "}
                            {moment(item?.created_at).calendar()}
                          </p>
                          <p className="text-base w-[90%]  ">
                            {item?.exam?.subcategory?.title?.english}
                          </p>
                          <p className="text-xs  w-[90%]  ">
                            Total time: {item?.exam?.total_time} minutes
                          </p>
                        </div>
                        <div className="flex justify-end">
                          <p className="mt-5">Score:</p>
                          <button
                            onClick={handleOpenError}
                            className="border rounded-lg bg-[#f97316] m-4 text-base px-4 py-1  text-black"
                          >
                            {item?.score}/{item?.exam?.questions_count}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}

             
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
