import React, { useContext } from "react";
import vector from "../assets/Vector.png";
import { TokenContext } from "../context/TokenContext";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";

const Footer = () => {
  const { myToken } = useContext(TokenContext);
  const { logout, token } = useAuth();
  const navigate = useNavigate();
  return (
    <>
      {!token ? (
        { logout }
      ) : (
        <>
          <div className="bg-[#f97316] h-[40%] px-[0%] lg:px-[5%] ">
            <div className="flex flex-col font-inter">
              <div className="flex flex-col lg:w-[100%] ">
                <div className="flex flex-col lg:flex-row mb-6">
                  {" "}
                  <div className="flex flex-col lg:w-[45%]">
                    <div className="flex">
                      <img
                        className="ml-[6%] w-[20%] mt-[8%] lg:mb-[4%] lg:w-[6%]"
                        alt="Vector"
                        src={vector}
                      />
                      <div
                        className="flex flex-col ml-[2%] mt-[7%] 
            "
                      >
                        <p className="text-xl font-bold text-white">
                          ClassABCD
                        </p>
                        <p className="text-2xl font-bold">Learning Center</p>
                      </div>
                    </div>
                    <div className=" text-white hidden  w-[85%] lg:flex mx-[5%] my-[5%]">
                      <p>
                        The classABCD is an online language education website
                        teaching English and Chinese. It is the best platform to
                        learn English, Chinese and many other languages{" "}
                      </p>
                    </div>
                  </div>
                  <div className=" grid lg:flex grid-cols-2 lg:w-[55%] "> 
                  <div
                    className="flex  pl-2 lg:pl-10 flex-col text-white lg:w-[100%]  
                  pt-10"
                  >
                    <p>Navigation Links</p>
                    <button className="my-1 mt-6 flex justify-start hover:underline">Services</button>
                    <button className="my-1 flex justify-start hover:underline">Insights</button>
                    <button className="my-1 flex justify-start hover:underline">About Us</button>
                    <button className="my-1 flex justify-start hover:underline">Careers</button>
                  </div>
                  <div className="flex flex-col text-white lg:pl-5 lg:w-[100%] pt-10">
                    <p>Others</p>
                 
                    <button className="my-1 mt-6 flex justify-start hover:underline">
                      Privacy policies
                    </button>
                    <button className="my-1 flex justify-start hover:underline">
                      Terms and Conditions
                    </button>
                    <button className="my-1 flex justify-start hover:underline">
                      Help 
                    </button>
                  </div>
                  <div className="flex flex-col pl-2 lg:pl-10 text-white  lg:w-[100%] pt-10">
                    <p>Address</p>
                    <p className="my-1 mt-6">(+251-955555555)</p>
                    <p className="my-1">classabcd.com</p>
                    <p className="my-1">Addis Ababa, Ethiopia</p>
                  </div>
                </div>
                </div>

                
              </div>{" "}
              <div className="flex flex-col lg:flex-row justify-center border-t pt-[2%] pb-[2%] text-xs lg:text-lg text-white">
                <p className="flex justify-center lg:w-[50%] mb-[1%] lg:justify-end lg:mr-[20%]">
                  Copyright 2023 | ClassABCD | All Rights reserved
                </p>
                <p className="flex justify-center lg:justify-start text-3xl lg:text-base my-4 lg:my-0 ">
                  <button className="mx-2">
                    <FaFacebookF />
                  </button>
                  <button className="mx-2">
                    <FaInstagram />
                  </button>
                  <button className="mx-2">
                    <FaLinkedin />
                  </button>
                  <button className="mx-2">
                    <FaTwitter />
                  </button>
                </p>
              </div>
            </div>
          </div>{" "}
        </>
      )}
    </>
  );
};

export default Footer;
