import axios from "axios";
import React, { useContext, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom/dist";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../context/auth";
import vector2 from "../../assets/Vector2.png";
import { CheckBox } from "@material-ui/icons";
import frame from "../../assets/Frame.png";
import FooterWave from "../../assets/FooterWave.png";
import victoryCup from "../../assets/victoryCup.gif";
import hotel from "../../assets/hotel.jpg";
import laptops from "../../assets/Laptops.png";
import phone from "../../assets/phone.png";
import backpack from "../../assets/backPack.png";
import books from "../../assets/books.png";
import mobileCard from "../../assets/mobileCard.png";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TokenContext } from "../../context/TokenContext";

const Login = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const { setMyToken } = useContext(TokenContext);
  const [agreed, setAgreed] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);

  const [openAgreement, setOpenAgreement] = React.useState(false);
  const handleOpenAgreement = () => setOpenAgreement(true);
  const handleCloseAgreement = () => setOpenAgreement(false);

  const [openRegister, setOpenRegister] = React.useState(false);
  const handleOpenRegister = () => setOpenRegister(true);
  const handleCloseRegister = () => setOpenRegister(false);

  const { login } = useAuth();
  const navigate = useNavigate();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  const LoginHandler = () => {
    loginMutationSubmitHandler();
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      console.log("You must have pressed Enter ");
      loginMutationSubmitHandler();
    }
  };

  const loginMutation = useMutation(
    async (newData) =>
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}login`, newData, {
        headers,
      }),
    {
      retry: false,
    }
  );

  const loginMutationSubmitHandler = async (values) => {
    try {
      loginMutation.mutate(
        { phone: userName, password },
        {
          onSuccess: (responseData) => {
            login(responseData?.data?.token, responseData?.data);
            console.log({ user: responseData?.data?.token });
            setMyToken(responseData?.data?.token);
            navigate("/");

            toast.success("Login success!", {
              position: toast.POSITION.TOP_RIGHT,
            });
          },
          onError: (err) => {
            console.log({ err });
            toast(err?.response?.data?.message);
          },
        }
      );
    } catch (err) {
      toast(err?.response?.data?.message ?? "user not found2");
    }
  };

  const handleChange = () => {
    setAgreed(true);
  };
  return (
    <>
      <div className="">
        <div className=" w-[100%]  lg:h-[100%] overflow-y-scroll lg:overflow-y-auto ">
          <div className="flex flex-col  ">
            <div className="  pb-[2%]  lg:pt-[2%] lg:flex lg:flex-row lg:pb-[0%] lg:h-[100%]">
              <div className="flex flex-col mt-[15%] lg:mt-[0%] lg:w-[50%] ">
                <div className="flex flex-row justify-center lg:justify-start">
                  <div className=" w-[30%] h-[30%] my-[4%] lg:my-[4%] lg:w-[9%] lg:h-[60%] lg:ml-[8%] lg:flex lg:flex-justify-end lg:mt-[4%] ">
                    <img
                      className=" lg:w-[100%]  "
                      alt="vector2"
                      src={vector2}
                    />
                  </div>

                  <div className=" pl-[0%] pt-[8%] flex flex-col lg:pt-[4%] lg:ml-[2%] ">
                    <p className="   font-inter  lg:text-2xl ">ClassABCD</p>
                    <p className="   font-inter xs:text-4xl sm:text-5xl md:text-3xl text-[#f97316] ">
                      Learning Center
                    </p>
                  </div>
                </div>
                <div className=" flex justify-center my-[1%] lg:mb-[0%] lg:justify-start lg:ml-[6%]">
                  <div className="w-[90%] h-[30%] lg:w-[100%] lg:text-base lg:h-[20%] ">
                    <p>
                      {" "}
                      The classABCD is an online language education website
                      teaching English and Chinese. It is the best platform to
                      learn English, Chinese and many other languages.{" "}
                    </p>
                  </div>
                </div>
                <div className="flex  justify-center lg:justify-start lg:ml-[6%] mb-[0%] lg:mb-[0%] ">
                  <div className=" w-[90%]   lg:w-[100%]">
                    <div className=" flex flex-col  m-[1%] border border-white rounded-lg lg:m-[0%] ">
                      <label className="font-Poppins pl-[8%] pt-[8%] lg:text-base">
                        Phone no:
                      </label>
                      <div class="flex mx-[10%]">
                        <span class="inline-flex items-center  px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md ">
                          +251
                        </span>
                        <input
                          placeholder="91173 90 28"
                          
                          onChange={(e) => {
                            setUserName("251" + e.target.value);
                          }}
                          class="rounded-none rounded-r-lg bg-gray-200 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  "
                          type="number"
                        />
                      </div>
                      {/* 
                    <p className="mx-[4%] lg:text-base ">
                      +251{" "}
                      <input
                        placeholder="91173 90 28"
                        onKeyDown={handleKeyDown}
                        onChange={(e) => {
                          setUserName(e.target.value);
                        }}
                        className=" bg-gray-200  w-[80%] my-[4%] rounded-lg lg:py-[2%] lg:text-base"
                        type="number"
                      />
                    </p> */}
                      <label className="font-Poppins pl-[8%] pt-[4%] lg:text-base">
                        Password:
                      </label>

                      <input
                        placeholder="Enter Password"
                       
                        className=" bg-gray-200 mx-[10%] w-[80%] my-[4%] rounded-lg  focus:ring-blue-500 focus:border-blue-500 lg:py-[2%] lg:text-base border-gray-300"
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        type="password"
                      />

                      <div class="flex items-start  ">
                        <input
                          id="default-checkbox"
                          type="checkbox"
                          value=""
                          class="w-[5%] lg:h-3 mt-[1%] ml-[6%] text-blue-600 bg-gray-100 border-[#f97316]  focus:ring-blue-500  focus:ring-2 lg:ml-[8%] lg:w-[3%]  lg:py-[0%] "
                          onChange={handleChange}
                        />
                        <button
                          for="default-checkbox"
                          class="ml-[2%] text-sm mt-[0.2%] lg:mt-[0%] font-bold text-[#f97316] lg:text-sm hover:underline hover:text-black"
                          onClick={handleOpenAgreement}
                        >
                          Agree to terms and conditions
                        </button>
                        <Modal
                          open={openAgreement}
                          onClose={handleCloseAgreement}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <div className="absolute top-[5%] left-[0%] max-h-[50%] lg:max-h-[100%] lg:left-[0%] px-[3%] mx-[3%] overflow-y-scroll lg:mx-[20%]  width-[50%] bg-white border-2 border-solid border-black rounded-lg shadow-lg p-4">
                            <p className="font-bold text-[#f97316] text-2xl">
                              TERMS AND CONDITIONS
                            </p>
                            <p className="m-4">
                              <p className="text-xl font-semibold">
                                Information we collect / የምንሰበስበው
                                መረጃ/Odeeffannoo sassaabnu.
                              </p>
                              Dear customer, you will be registered as a user of
                              the service by sending OK to 8601.
                              <br /> ውድ ደንበኛችሁ ok ወደ 8601 በመላክ የአገልግሎቱ ተጠቃሚ መሆን
                              ትችላላችሁ <br /> Kabajamoo maamilaa Tole gara 8601
                              erguun faayidaa bashannanaa tajaajila keenyaa
                              ta'aa galmaa'aa. <br />
                              <br />
                              <p className="text-xl font-semibold">
                                Payment/ ክፍያ / Kaffaltii
                              </p>{" "}
                              Once you subscribed to our website or service, we
                              charge 2 birr per day untill you send an
                              unsubscription request. you can send unsubscribe
                              request by clicking "unsubscribe" link on the home
                              page. <br />
                              ዉድ ደምበኛችን ድረ-ገጻችን ወይም አገልግሎታችን ላይ ከተመዘገቡ በኋላ በቀን 2
                              ብር እናስከፍላለን። አገልግሎቱን ለማቋረጥ በመነሻ ገጹ ላይ "ከደንበኝነት
                              ምዝገባ ይውጡ" የሚለውን አገናኝ ጠቅ በማድረግ ከደንበኝነት ምዝገባ መውጣት
                              ትችላላችሁ።
                              <br />
                              <br /> Akka marsaritii keenyaatti maamila tahuuf
                              galmooftanii hanga galmee maamilummaa nu
                              qaqqabutti qarshii lama guyyaatti ni
                              kaffalchiifna.Jalqaba seensa marsariitii keenyaa
                              irratti galmee maamilummaa keessaa bahaa kan jedhu
                              cuqaasuun galmee maamilummaa keessaa bahuu ergaa
                              erguu ni dandeessu.
                            </p>
                          </div>
                        </Modal>
                      </div>

                      {agreed ? (
                        <>
                          {" "}
                          {loginMutation.isLoading ? (
                            <div className="flex justify-center">
                              {" "}
                              <button
                                disabled
                                className="bg-[#f97316] w-[80%] py-[4%] my-[4%] rounded-lg "
                              >
                                <p className=" text-white  lg:text-base">
                                  Logging in
                                </p>
                              </button>
                            </div>
                          ) : (
                            <div className="flex justify-center">
                              <button
                                onClick={LoginHandler}
                                type="submit"
                                className="bg-[#f97316] w-[80%] py-[4%] my-[4%] rounded-lg"
                              >
                                <p className="text-white lg:text-base">Login</p>
                              </button>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="flex justify-center">
                            <button
                              disabled
                              className="bg-[#f97316] w-[80%] py-[4%] my-[4%] rounded-lg opacity-50 hover:cursor-not-allowed"
                            >
                              <p className="text-white lg:text-base">Login</p>
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="flex justify-center">
                      <button
                        className="border-[#f97316] border w-[80%] my-[2%] py-[4%] rounded-lg"
                        onClick={handleOpenRegister}
                      >
                        <div
                          className="text-[#f97316] font-bold lg:text-base"
                          href=""
                        >
                          Register
                        </div>
                      </button>

                      <Modal
                        open={openRegister}
                        onClose={handleCloseRegister}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <div className="absolute top-[5%] left-[0%] max-h-[50%] lg:max-h-[100%] lg:left-[0%] px-[3%] mx-[3%]  lg:mx-[20%]  width-[50%] bg-white border-2 border-solid border-black rounded-lg shadow-lg p-4">
                          <div className="flex justify-end"><button className="font-bold text-lg" onClick={handleCloseRegister}>x</button></div>
                          <p className="font-bold text-[#f97316] text-2xl">

                            Registration / ምዝገባ / Galmee
                          </p>
                          <p className="m-4">
                            Dear customer, you will be registered as a user of
                            the service by sending OK to 8601. <br />
                            ውድ ደንበኛችን ok ወደ 8601 በመላክ የአገልግሎቱ ተጠቃሚ መሆን ትችላላችሁ ::
                            <br /> Kabajamoo maamilaa Tole gara 8601 erguun
                            faayidaa bashannanaa tajaajila keenyaa ta'aa
                            galmaa'aa.
                          </p>
                            <div className="flex justify-end">
                              <button className="bg-[#f97316] w-[40%] py-[4%] lg:py-[2%] lg:w-[20%] my-[4%] rounded-lg">
                          <a href="sms:8601?body=ok">
                                <p className="text-white lg:text-base">Send</p>
                          </a>
                              </button>
                            </div>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
              <div className="  lg:w-[100%] lg:flex flex-col  lg:justify-start  ">
                <img
                  className=" px-[17%] pl-[10%]  lg:px-[37%] "
                  alt="vector2"
                  src={victoryCup}
                />
                <div className=" flex justify-center lg:text-base   font-bold">
                  <p className="flex text-center">
                    {" "}
                    Test your skills. Win exciting prizes!
                    <br />
                    Join now for a rewarding learning experience!
                  </p>
                </div>
                <div className="lg:pl-[20%] pt-[2%]">
                  <div
                    className="flex flex-row  overflow-x-scroll lg:grid 
                   lg:grid-cols-3   lg:scrollbar-hide"
                  >
                    <img
                      className="hidden lg:flex w-[80%] py-[20%] lg:py-[0%]"
                      alt="vector2"
                      src={laptops}
                    />
                    <img
                      className=" w-[60%] py-[20%] px-[10%] lg:py-[0%] pb-[20%]"
                      alt="vector2"
                      src={backpack}
                    />
                    <img
                      className=" w-[50%] py-[20%] lg:py-[0%]"
                      alt="vector2"
                      src={phone}
                    />
                    <img
                      className=" w-[50%] py-[20%] lg:py-[0%]"
                      alt="vector2"
                      src={mobileCard}
                    />
                    <img
                      className=" w-[80%] py-[20%] lg:py-[0%]"
                      alt="vector2"
                      src={books}
                    />

                    <img
                      className=" w-[50%] lg:w-[60%] lg:py-[0%] py-[15%] mx-[2%] lg:pb-[0%] "
                      alt="vector2"
                      src={hotel}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer />
      </div>
    </>
  );
};

export default Login;
