import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import vector2 from "../../assets/Vector2.png";
import vector3 from "../../assets/Vector3.png";
import vector4 from "../../assets/Vector4.png";
import vector5 from "../../assets/Vector5.png";
import Footer from "../../components/Footer";
import { useAuth } from "../../context/auth";
import { useMutation, useQuery } from "react-query";
import ClampLines from "react-clamp-lines";
import unsplash4 from "../../assets/unsplash4.png";
import axios from "axios";
import { toast } from "react-toastify";
import { ThreeCircles } from "react-loader-spinner";
import { BiLogOut } from "react-icons/bi";
import { MdOutlineQuiz } from "react-icons/md";
import { LanguageContext } from "../../context/LanguageContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
} from "@mui/material";
import Category from "../category/Category";
import { FaBars } from "react-icons/fa";
import Modal from "@mui/material/Modal";
import MultiClamp from "react-multi-clamp";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import MostRecent from "../../components/MostRecent";

const style = {
  position: "absolute",
  top: "20%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SearchPage = () => {
  const navigate = useNavigate();
  const {
    isEnglishLang,
    setIsEnglishLang,
    isAmharicLang,
    setIsAmharicLang,
    isOromoLang,
    setIsOromoLang,
  } = useContext(LanguageContext);

  const [SearchApi, setSearchApi] = useState(null);
  const [menu, setMenu] = useState("Menu");

  const [search, setSearch] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const [openBars, setOpenBars] = useState(false);
  const handleOpenBars = () => setOpenBars(true);
  const handleCloseBars = () => setOpenBars(false);

  const { token, user, logout } = useAuth();
  const headers = {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };

  const SearchData = useQuery(
    ["SearchDataApi", SearchApi, search],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}search-post?search_text=${search}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res);
        setSearchApi(null);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          logout();
        }
      },
    }
  );
  const Search = () => {
    console.log(search);
    setSearchApi(1);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      console.log("You must have pressed Enter ");
      setSearchApi(1);
    }
  };

  const HomePageData = useQuery(
    ["HomePageDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}homepage`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          logout();
        }
      },
    }
  );
  const formik = useFormik({
    initialValues: {
      searchInput: "",
    },
    validationSchema: Yup.object({
      searchInput: Yup.string().required("Search field is required"),
    }),
    onSubmit: () => {
      Search();
    },
  });

  return (
    <>
      <div className="w-[100%] h-[100%] bg-gray-100 overflow-hidden">
        <div className="">
          <div className="flex font-inter ">
            <div className="flex flex-col w-[100%] ">
              <div className="lg:flex lg:flex-row lg:h-[25%]">
                <div className="lg:flex lg:flex-col lg:w-[70%]  lg:px-[5%]">
                  <div ClassName=" flex   ">
                    <div className="flex flex-col">
                      <div className="flex flex-col w-[100%] my-[2%] mx-[8%] lg:ml-[5%] lg:mr-[20%]  lg:h-8">
                        <div className="flex ">
                          <div className="lg:w-[3.5%] h-[75%]">
                            <img
                              className="w-[100%] h-[70%] mt-1 lg:w-[100%] lg:h-[100%]"
                              alt="Vector"
                              src={vector3}
                            />
                          </div>
                          <input
                            id="searchInput"
                            className="w-[75%] lg:w-[75%] rounded-lg  border border-black pl-[2%] text-sm  py-2 "
                            placeholder="Type something..."
                            onKeyDown={handleKeyDown}
                            defaultValue={search}
                            onChange={(e) => {
                              if (e.target.value != "") {
                                setSearch(e.target.value);
                              } else {
                                setSearch(null);
                              }
                            }}
                          />{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  {isEnglishLang ? (
                    <>
                      {" "}
                      <div className="flex justify-center lg:justify-start lg:pl-[2%] text-3xl my-[2%]">
                        Results
                      </div>
                    </>
                  ) : isAmharicLang ? (
                    <>
                      <div className="flex justify-center lg:justify-start lg:pl-[2%] text-3xl my-[2%]">
                        ውጤቶች
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex justify-center lg:justify-start lg:pl-[2%] text-3xl my-[2%]">
                        Bu'aawwan
                      </div>
                    </>
                  )}
                  {SearchData.isFetching ? (
                    <div className="h-[100vh] flex items-start justify-center min-h-0">
                      <div ClassName="">
                        <ThreeCircles
                          height="100"
                          width="300"
                          color="#f97316"
                          ariaLabel="ThreeCircles-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      </div>
                    </div>
                  ) : SearchData?.data?.data?.length === 0 ? (
                    <>
                      <div className="pb-[57%] lg:py-[0%] pl-[8%] ">
                        <p ClassName="   p-[%] "> No Results.</p>
                      </div>
                    </>
                  ) : SearchData.isFetched ? (
                    <div className="flex flex-col bg-white">
                      {SearchData?.data?.data?.map((item, i) => {
                        return (
                          <>
                            <div key={item}>
                              <div className="flex flex-col bg-white  p-1 mt-4 mr-4  rounded lg">
                                <div>
                                  <div className="flex flex-col shadow-lg">
                                    <div className="flex flex-row">
                                      {" "}
                                      <div
                                        className="  w-[24%] h-[100%]
                                    "
                                      >
                                        <img
                                          className=" object-cover h-20 w-full rounded-lg  "
                                          alt="unsplash"
                                          src={item?.thumbnail?.link}
                                        />
                                      </div>
                                      <div className="flex flex-col  w-[70%]">
                                        <div className="  text-sm text-left lg:text-xs">
                                          {isEnglishLang ? (
                                            <>
                                              <button
                                                onClick={() => {
                                                  navigate(
                                                    `/details/${item.id}`
                                                  );
                                                }}
                                              >
                                                <p className="text-left pt-4 pl-4 lg:font-bold">
                                                  {item.title.english}
                                                </p>

                                                <ReactQuill
                                                  className="max-h-12 overflow-hidden"
                                                  value={item.body.english}
                                                  readOnly={true}
                                                  theme={"bubble"}
                                                />
                                              </button>{" "}
                                            </>
                                          ) : isAmharicLang ? (
                                            <>
                                              <button
                                                onClick={() => {
                                                  navigate(
                                                    `/details/${item.id}`
                                                  );
                                                }}
                                              >
                                                <p className=" pl-4 lg:font-bold text-left">
                                                  {item.title.english}
                                                </p>
                                                <ReactQuill
                                                  className="max-h-12 overflow-hidden"
                                                  value={item.body.amharic}
                                                  readOnly={true}
                                                  theme={"bubble"}
                                                />
                                              </button>
                                            </>
                                          ) : (
                                            <>
                                              <button
                                                onClick={() => {
                                                  navigate(
                                                    `/details/${item.id}`
                                                  );
                                                }}
                                              >
                                                <p className=" pl-4 lg:font-bold text-left">
                                                  {item.title.english}
                                                </p>
                                                <ReactQuill
                                                  className="max-h-12 overflow-hidden"
                                                  value={item.body.oromiffa}
                                                  readOnly={true}
                                                  theme={"bubble"}
                                                />
                                              </button>
                                            </>
                                          )}
                                          <br />
                                        </div>
                                        <div>
                                          <p className="lg:text-xs lg:ml-[2%] lg:my-[1%]">
                                            {" "}
                                            {item.created_at}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
                <div className=" hidden  w-[30%] lg:flex  lg:flex-col justify-start ">
                  <MostRecent />
                </div>
              </div>
            </div>
          </div>
          <div className=" sm:mt-[0%] md:mt-[0%] lg:mt-[0%]"></div>
        </div>
      </div>
    </>
  );
};

export default SearchPage;
