import React from "react";
import user from "../../../assets/unsplash4.png";
import prize from "../../../assets/mobileCard.png";
import Vector2 from "../../../assets/Vector2.png";
import Badge from "../../../assets/Badge.svg";

const WinnerListTable = ({ item, No }) => {
  return (
    <>
      <tr>
        <td class="px-6 py-4">
          <p>{No + 1}</p>
        </td>
        <td class="px-6 py-4 flex justify-center ">
          <p className="w-[100%] ">
            {item?.winner_photo?.link ? (
              <>
                {" "}
                <img
                  className=" object-cover h-20 w-full rounded-lg  "
                  src={item?.winner_photo?.link}
                />
              </>
            ) : (
              <>
                {" "}
                <img className=" object-fit   rounded-lg  " src={Vector2} />
              </>
            )}
          </p>
        </td>
        <td class="px-6 py-4">
          <p> {item.name}</p>
        </td>
        <td class="px-6 py-4">
          <p> {item.formatted_phone}</p>
        </td>
        <td class=" py-4  ">
          {item.type == "Daily" ? (<p className=" ml-8 rounded-lg w-[50%] bg-green-300 text-green-800  ">
            {item.type}
          </p>): item.type == "Weekly" ? (   <p className=" ml-8 rounded-lg w-[50%] bg-yellow-300 text-yellow-600  ">
            {item.type}
          </p>): (   <p className=" ml-8  rounded-lg w-[50%] bg-orange-300 text-orange-600  ">
            {item.type}
          </p>) }
       
        </td>
       
        <td class="px-6 py-4">
          <p>
            {" "}
            {item?.prize_photo?.link ? (
              <>
                <div className="w-[100%] ">
                  <p className="text-center  text-xs opacity-50 font-bold">
                    Prize
                  </p>
                  <img
                    className=" object-cover h-20 w-[100%] rounded-lg  "
                    src={item?.prize_photo?.link}
                  />
                </div>
              </>
            ) : (
              <>
                <p className="text-center pl-6 text-xs opacity-50 font-bold">
                  Prize
                </p>
                <div>
                  <img
                    className=" object-cover h-[100%] pl-6 w-[100%] rounded-lg  "
                    src={Badge}
                  />
                </div>
              </>
            )}
          </p>
        </td>
        <td class=" py-4  ">
          <p className=" ml-12 font-bold w-[40%]  ">
            {item.prize_name}
          </p>
        </td>
      </tr>
    </>
  );
};

export default WinnerListTable;
