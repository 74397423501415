import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import vector2 from "../../assets/Vector2.png";
import vector3 from "../../assets/Vector3.png";
import vector4 from "../../assets/Vector4.png";
import unsplash from "../../assets/unsplash.png";
import unsplash2 from "../../assets/unsplash2.png";
import Footer from "../../components/Footer";
import { useQuery } from "react-query";
import axios from "axios";
import { useAuth } from "../../context/auth";
import { Button, Card, Menu, MenuItem } from "@mui/material";
import ClampLines from "react-clamp-lines";
import unsplash4 from "../../assets/unsplash4.png";
import unsplash5 from "../../assets/unsplash5.png";
import { LanguageContext } from "../../context/LanguageContext";
import ReactPlayer from "react-player";
import { ThreeCircles } from "react-loader-spinner";
import { BiLogOut } from "react-icons/bi";
import { Box, FormControl, InputLabel, Select } from "@mui/material";
import { FaBars } from "react-icons/fa";
import { BiPlay } from "react-icons/bi";
import { MdOutlineQuiz } from "react-icons/md";
import Modal from "@mui/material/Modal";

import laptops from "../../assets/Laptops.png";
import laptop from "../../assets/laptop.png";
import phone from "../../assets/phone.png";
import backpack from "../../assets/backPack.png";
import books from "../../assets/books.png";
import mobileCard from "../../assets/mobileCard.png";
import giftBox from "../../assets/GiftBox.png";

import Marquee from "react-fast-marquee";
import ReactQuill, { Quill } from "react-quill";

import "react-quill/dist/quill.bubble.css";
import NavBar from "../../components/NavBar";
import Winners from "../../components/Winners";



const Details = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    isEnglishLang,
    setIsEnglishLang,
    isAmharicLang,
    setIsAmharicLang,
    isOromoLang,
    setIsOromoLang,
  } = useContext(LanguageContext);
  const [isScholarship, setIsScolarship] = useState(true);
  const [isAmharic, setIsAmharic] = useState(false);

  const [isEnglish, setIsEnglish] = useState(false);
  const [isChinese, setIsChinese] = useState(false);
  const [next, setNext] = useState(false);
  const [prev, setPrev] = useState(true);
  const [after, setAfter] = useState(false);
  const [details, setDetails] = useState(null);
  const { token, user, logout } = useAuth();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openBars, setOpenBars] = useState(false);
  const handleOpenBars = () => setOpenBars(true);
  const handleCloseBars = () => setOpenBars(false);

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const DetailData = useQuery(
    ["DetailDataApi", details],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}show-post/${id}`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {
        setDetails(null);
        //  console.log(categoryData?.data?.data?.data[1]?.name?.amharic);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          logout();
        }
      },
    }
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [DetailData]);

  const HomePageData = useQuery(
    ["HomePageDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}homepage`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          logout();
        }
      },
    }
  );

  return (
    <>
      <div className="w-[100%] h-[100%] bg-gray-100 overflow-hidden">
        <div className="">
          <div className="flex font-inter ">
            <div className="flex flex-col w-[100%] ">
              <div className="lg:flex lg:flex-row lg:h-[25%] ">
                <div className="lg:flex lg:flex-col lg:w-[90%] lg:pl-[5%]  lg:pr-[5%]">
                  {DetailData?.isFetching ? (
                    <>
                      {" "}
                      <div className="h-[100vh] flex items-center justify-center min-h-0">
                        <div ClassName="">
                          <ThreeCircles
                            height="100"
                            width="300"
                            color="#f97316"
                            ariaLabel="ThreeCircles-loading"
                            visible={true}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className=" h-[100%] p-[2%]  flex flex-col justify-center  lg:justify-start lg:max-h-[100%] lg:max-w-[100%] bg-white lg:bg-gray-100">
                        <div className="flex justify-center flex-col lg:justify-start  text-xl my-[0%] lg:ml-[0%] bg-white lg:p-[2%] lg:shadow-lg">
                          <div className="flex justify-center  lg:justify-center w-[100%] lg:bg-white ">
                            <img
                              className=" h-[100%] w-[60%] rounded-lg object-cover lg:overflow-clip lg:h-[100%] lg:w-[80%]  "
                              alt="unsplash"
                              src={
                                DetailData?.data?.data?.post?.thumbnail?.link
                              }
                            />
                          </div>
                          <div className="font-bold mt-2">
                            {isEnglishLang ? (
                              <>
                                {DetailData?.data?.data?.post?.title?.english}
                              </>
                            ) : isAmharicLang ? (
                              <>
                                {DetailData?.data?.data?.post?.title?.amharic}
                              </>
                            ) : (
                              <>
                                {DetailData?.data?.data?.post?.title?.oromiffa}
                              </>
                            )}
                          </div>
                        </div>
                        <div className=" pl-[2%] text-xs  lg:bg-white ">
                          {DetailData?.data?.data?.post?.created_at}
                        </div>
                        <div className="bg-white lg:p-[2%] shadow-lg">
                          <div className="flex justify-center lg:w-[90%]  lg:justify-start text-lg mx-[6%] lg:mx-[6%] ">
                            {" "}
                            {isEnglishLang ? (
                              <>
                                {" "}
                                <ReactQuill
                                 
                                  value={ DetailData?.data?.data?.post?.body?.english}
                                  readOnly={true}
                                  theme={"bubble"}
                                />
                              </>
                            ) : isAmharicLang ? (
                              <>
                                {" "}
                                <ReactQuill
                                  value={
                                    DetailData?.data?.data?.post?.body?.amharic
                                  }
                                  readOnly={true}
                                  theme={"bubble"}
                                />
                              </>
                            ) : (
                              <>
                                {" "}
                                <ReactQuill
                                  value={
                                    DetailData?.data?.data?.post?.body?.oromiffa
                                  }
                                  readOnly={true}
                                  theme={"bubble"}
                                />
                              </>
                            )}
                          </div>
                          <div>
                            <p class="pt-[10%] pb-4"></p>
                            {DetailData?.data?.data?.post?.file?.type?.startsWith(
                              "video"
                            ) ? (
                              <>
                                <b>Video:</b>
                                <div className="flex justify-center">
                                  <Card
                                    sx={{ width: "70%", height: "16%" }}
                                    raised={true}
                                  >
                                    {/* <img  alt={"s"} src={DetailData?.data?.data?.post?.file?.link}/> */}

                                    <ReactPlayer
                                      url={
                                        DetailData?.data?.data?.post?.file?.link
                                      }
                                      controls
                                    />
                                  </Card>
                                </div>
                              </>
                            ) : null}
                          </div>{" "}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="   lg:w-[40%] flex  flex-col   justify-center lg:justify-start ">
                  {DetailData?.data?.data?.related ? (
                    <>
                      <div className=" ">
                        <div>
                          {DetailData?.data?.data?.related?.map((item, i) => {
                            return (
                              <>
                                <div key={item}>
                                  {i == 0 ? (
                                    <p className="font-bold text-2xl flex-col  m-2 mt-6 lg:mt-0 lg:m-0 ">
                                      {isEnglishLang ? (
                                        <> Related</>
                                      ) : isAmharicLang ? (
                                        <>ተዛማጅ</>
                                      ) : (
                                        <>Kan itti dhiyaatu</>
                                      )}
                                    </p>
                                  ) : null}

                                  <div className="flex flex-col bg-white  p-1 mt-4 mr-4  rounded lg">
                                    <div>
                                      <div className="flex flex-col shadow-lg">
                                        <div className="flex flex-row">
                                          {" "}
                                          <div
                                            className="  w-[24%] h-[100%]
                                            "
                                          >
                                            <img
                                              className=" object-cover h-20 w-full rounded-lg  "
                                              alt="unsplash"
                                              src={item?.thumbnail?.link}
                                            />
                                          </div>
                                          <div className="flex flex-col  w-[70%]">
                                            <div className="  text-sm text-left lg:text-xs">
                                              {isEnglishLang ? (
                                                <>
                                                  <button
                                                    onClick={() => {
                                                      setDetails(1);
                                                      navigate(
                                                        `/details/${item.id}`
                                                      );
                                                    }}
                                                  >
                                                    <p className="text-left pt-1 pl-4 lg:font-bold">
                                                      {item.title.english}
                                                    </p>
                                                    <ReactQuill
                                                      className="max-h-12 overflow-hidden hover:underline"
                                                      value={item.body.english}
                                                      readOnly={true}
                                                      theme={"bubble"}
                                                    />
                                                  </button>{" "}
                                                </>
                                              ) : isAmharicLang ? (
                                                <>
                                                  <button
                                                    onClick={() => {
                                                      setDetails(1);
                                                      navigate(
                                                        `/details/${item.id}`
                                                      );
                                                    }}
                                                  >
                                                    <p className=" pt-1 lg:font-bold">
                                                      {item.title.amharic}
                                                    </p>
                                                    <ReactQuill
                                                      className="max-h-12 overflow-hidden hover:underline"
                                                      value={item.body.amharic}
                                                      readOnly={true}
                                                      theme={"bubble"}
                                                    />
                                                  </button>
                                                </>
                                              ) : (
                                                <>
                                                  <button
                                                    onClick={() => {
                                                      setDetails(1);
                                                      navigate(
                                                        `/details/${item.id}`
                                                      );
                                                    }}
                                                  >
                                                    <p className=" pt-1 lg:font-bold">
                                                      {item.title.oromiffa}
                                                    </p>
                                                    <ReactQuill
                                                      className="max-h-12 overflow-hidden hover:underline"
                                                      value={item.body.oromiffa}
                                                      readOnly={true}
                                                      theme={"bubble"}
                                                    />
                                                  </button>
                                                </>
                                              )}
                                              <br />
                                            </div>
                                            <div>
                                              <p className="lg:text-xs pl-2 lg:pt-2 lg:ml-[2%]">
                                                {" "}
                                                {item.created_at}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </>
                  ) : DetailData?.data?.data?.related.length ==
                    "0" ? null : null}

                  <div className="   w-[100%] mt-10 lg:flex  lg:flex-col justify-start ">
                    {DetailData?.data?.data?.relatedCategories ? (
                      <>
                        <div className=" ">
                          <div>
                            {DetailData?.data?.data?.relatedCategories?.map(
                              (item, i) => {
                                return (
                                  <>
                                    <div key={item}>
                                      {i == 0 ? (
                                        <p className="font-bold text-2xl m-2 lg:m-0">
                                          {isEnglishLang ? (
                                            <> Related Categories</>
                                          ) : isAmharicLang ? (
                                            <>ተዛማጅ ምድቦች</>
                                          ) : (
                                            <>Ramaddiiwwan walqabatan</>
                                          )}
                                        </p>
                                      ) : null}

                                      <div className="flex flex-col bg-white mt-4  mr-4  rounded lg">
                                        <div>
                                          <div className="flex flex-col shadow-lg">
                                            <div className="flex flex-row">
                                              {" "}
                                              <div className="flex flex-col  w-[100%]">
                                                <div className="  text-sm text-left lg:text-xs">
                                                  {isEnglishLang ? (
                                                    <>
                                                      <button
                                                        onClick={() => {
                                                          setDetails(1);
                                                          navigate(
                                                            `/categories/${item.id}`
                                                          );
                                                        }}
                                                      >
                                                        <p className=" flex text-left my-4 pl-4 lg:font-bold">
                                                          <div className="text-base ">
                                                            <BiPlay />
                                                          </div>
                                                          {item.title.english}
                                                        </p>
                                                      </button>{" "}
                                                    </>
                                                  ) : isAmharicLang ? (
                                                    <>
                                                      <button
                                                        onClick={() => {
                                                          setDetails(1);
                                                          navigate(
                                                            `/categories/${item.id}`
                                                          );
                                                        }}
                                                      >
                                                        {" "}
                                                        <p className=" flex text-left my-4 pl-4 lg:font-bold">
                                                          <div className="text-base ">
                                                            <BiPlay />
                                                          </div>
                                                          {item.title.amharic}
                                                        </p>
                                                      </button>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <button
                                                        onClick={() => {
                                                          setDetails(1);
                                                          navigate(
                                                            `/categories/${item.id}`
                                                          );
                                                        }}
                                                      >
                                                        {" "}
                                                        <p className=" flex text-left my-4 pl-4 lg:font-bold">
                                                          <div className="text-base ">
                                                            <BiPlay />
                                                          </div>
                                                          {item.title.oromiffa}
                                                        </p>
                                                      </button>
                                                    </>
                                                  )}
                                                  <br />
                                                </div>
                                              </div>
                                            </div>
                                            <div></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>

                  <div className="mt-10">
                    <Winners />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center">
          <div className="flex justify-center mt-[3%]">
            <img src={giftBox} />
          </div>
          <div>
            <p className="lg:text-2xl flex justify-center font-bold mx-[3%]">
              Test your skills, Win exciting prizes! Join now for a rewarding
              learning experience!
            </p>
          </div>
          <div className="flex justify-center ">
            <div className="flex  flex-row  lg:w-[80%] mb-[8%] h-56">
              <Marquee>
                <img
                  className=" pb-[%]  pt-[%]  px-[15%] "
                  alt="vector2"
                  src={laptops}
                />

                <img
                  className=" pb-[%] pt-[25%]  px-[15%] "
                  alt="vector2"
                  src={phone}
                />
                <img
                  className=" pb-[%] pt-[25%]  px-[15%] "
                  alt="vector2"
                  src={backpack}
                />
                <img
                  className=" pb-[%] pt-[25%]  px-[15%]"
                  alt="vector2"
                  src={books}
                />
                <img
                  className=" pb-[%] pt-[25%]  px-[15%]"
                  alt="vector2"
                  src={laptop}
                />
                <img
                  className=" pb-[%] pt-[25%] px-[15%]"
                  alt="vector2"
                  src={mobileCard}
                />
                <img
                  className=" pb-[%]  pt-[5%]  px-[15%] "
                  alt="vector2"
                  src={laptops}
                />
                <img
                  className=" pb-[%] pt-[25%]  px-[15%] "
                  alt="vector2"
                  src={phone}
                />
                <img
                  className=" pb-[%] pt-[25%]  px-[15%] "
                  alt="vector2"
                  src={backpack}
                />
                <img
                  className=" pb-[%] pt-[25%]  px-[15%]"
                  alt="vector2"
                  src={books}
                />
                <img
                  className=" pb-[%] pt-[25%]  px-[15%]"
                  alt="vector2"
                  src={laptop}
                />
                <img
                  className=" pb-[%] pt-[25%] px-[15%]"
                  alt="vector2"
                  src={mobileCard}
                />
              </Marquee>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
