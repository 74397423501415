import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAuth } from "../../../context/auth";
import { LanguageContext } from "../../../context/LanguageContext";
import { AccessContext } from "../../../context/AccessContext";
import WinnerListTable from "./WinnerListTable";
import { ThreeCircles } from "react-loader-spinner";
import ReactQuill from "react-quill";

import { useQuery } from "react-query";
import axios from "axios";
import Pagination from "../../../components/Pagination";

const WinnersList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    isEnglishLang,
    setIsEnglishLang,
    isAmharicLang,
    setIsAmharicLang,
    isOromoLang,
    setIsOromoLang,
  } = useContext(LanguageContext);

  const { noAccess, setNoAccess } = useContext(AccessContext);
  const { token, user, logout } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const WinnersData = useQuery(
    ["WinnersDataApi", currentPage],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}winners?page=${currentPage}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {
        //  console.log(categoryData?.data?.data?.data[1]?.name?.amharic);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          console.log(res.message);
        }
      },
    }
  );

  return (
    <div>
      {" "}
      <div className="flex flex-col font-inter bg-gray-100 justify-start mx-10 mb-4">
        <p className="text-left text-2xl mb-2 font-bold w-[90%] ">
          {isEnglishLang ? (
            <>Winners List </>
          ) : isAmharicLang ? (
            <>ፈተናዎች </>
          ) : (
            <>Qormaata </>
          )}{" "}
        </p>

        <p className="text-left text-sm  opacity-50 mb-3 ">
          Check out our list of exam winners for inspiration on how to make it
          to the top next time!
        </p>
      </div>
      <div className="flex justify-end p-2"></div>
      <div>
        <div class="relative shadow-md sm:rounded-lg mx-10">
          <table class="w-full font-inter  text-gray-500 ">
            <thead class=" opacity-50 text-center overflow-x-scroll ">
              <tr>
                <th class=" py-3 ">No. </th>

                <th class="px-6 py-3">Photo</th>
                <th class="px-6 py-3">Full Name</th>
                <th class="px-6 py-3">Phone Number</th>
                <th class="px-6 py-3">Prize Type</th>

                <th class="px-6 py-3">Prize Photo</th>
                <th class="px-6 py-3">Prize Details</th>
              </tr>
            </thead>
            <tbody className="text-center overflow-x-scroll">
              {" "}
              {WinnersData?.isFetching ? (
                <>
                  {" "}
                  <div className="h-[100vh]  flex items-start justify-center min-h-0">
                    <div className="">
                      <ThreeCircles
                        height="50"
                        width="100"
                        color="#f97316"
                        ariaLabel="ThreeCircles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {WinnersData?.data?.data?.data?.map((item, i) => {
                    return (
                      <>
                        <WinnerListTable item={item} No={i} />
                      </>
                    );
                  })}{" "}
                 
                </>
              )}
            </tbody>
            
          </table>
          <div className="flex justify-center py-6 pr-10 ">
                    {WinnersData?.data?.data?.links?.map((item, id) => {
                      return (
                        <div key={id}>
                          <Pagination
                            item={item}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            MainData={WinnersData}
                          />
                        </div>
                      );
                    })}
                  </div>
        </div>
      </div>
    </div>
  );
};

export default WinnersList;
