import React, { useContext, useState } from "react";

import vector2 from "../../assets/Vector2.png";
import vector3 from "../../assets/Vector3.png";
import vector4 from "../../assets/Vector4.png";
import { useNavigate, useParams } from "react-router-dom";

import Footer from "../../components/Footer";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useQuery } from "react-query";
import axios from "axios";
import { useAuth } from "../../context/auth";
import ClampLines from "react-clamp-lines";
import { LanguageContext } from "../../context/LanguageContext";

import laptops from "../../assets/Laptops.png";
import laptop from "../../assets/laptop.png";
import phone from "../../assets/phone.png";
import backpack from "../../assets/backPack.png";
import books from "../../assets/books.png";
import mobileCard from "../../assets/mobileCard.png";
import giftBox from "../../assets/GiftBox.png";
import altExam from "../../assets/Exam-icon-1.png";

import { AiOutlineInfoCircle } from "react-icons/ai";

import Marquee from "react-fast-marquee";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  Typography,
} from "@mui/material";
import { ThreeCircles } from "react-loader-spinner";
import { BiHistory, BiLogOut, BiTime } from "react-icons/bi";
import { MdOutlineQuiz } from "react-icons/md";
import { FaBars } from "react-icons/fa";
import Modal from "@mui/material/Modal";
import MultiClamp from "react-multi-clamp";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import ExamDetails from "./ExamDetals";
import unsplash from "../../assets/unsplash.png";
import { AccessContext } from "../../context/AccessContext";
import Winners from "../../components/Winners";
import Exercises from "./Exercises";

const style = {
  position: "absolute",
  top: "20%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const styleError = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,

  p: 4,
};

const Exams = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    isEnglishLang,
    setIsEnglishLang,
    isAmharicLang,
    setIsAmharicLang,
    isOromoLang,
    setIsOromoLang,
  } = useContext(LanguageContext);

  const { noAccess, setNoAccess } = useContext(AccessContext);

  const [next, setNext] = useState(false);
  const [prev, setPrev] = useState(true);
  const [examId, setExamId] = useState(null);

  const [counter, setCounter] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { token, user, logout } = useAuth();

  const [openBars, setOpenBars] = useState(false);
  const handleOpenBars = () => setOpenBars(true);
  const handleCloseBars = () => setOpenBars(false);

  const [openRules, setOpenRules] = useState(false);
  const handleOpenRules = () => setOpenRules(true);
  const handleCloseRules = () => setOpenRules(false);
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const HomePageData = useQuery(
    ["HomePageDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}homepage`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
        }
      },
    }
  );
  const ExamDataFetch = useQuery(
    ["ExamDataFetchApi", examId],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}exams/${examId}`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res);
        setNoAccess(true);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
        }
      },
    }
  );

  const ExamData = useQuery(
    ["ExamsDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}exams?exams`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res);
      },
      onError: (res) => {
        if (res?.response?.status == 403) {
          navigate("/access-denied");
        }
      },
    }
  );

  console.log(ExamData);

  return (
    <>
      <div className="w-[100%] h-[100%]  overflow-hidden">
        {ExamData.isFetching ? (
          <>
            <div className="h-[100vh] flex items-start justify-center min-h-0">
              <div ClassName="">
                <ThreeCircles
                  height="100"
                  width="300"
                  color="#f97316"
                  ariaLabel="ThreeCircles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="">
              <div className="flex font-inter bg-gray-100 justify-center">
                <div className="flex flex-col w-[90%] ">
                  <div className="flex justify-center">
                    <div className="lg:flex lg:flex-row   lg:justify-start ">
                      <div className="lg:w-[70%] flex flex-col">
                        <p className="text-left text-2xl mb-4 font-semibold w-[90%]">
                          {isEnglishLang ? (
                            <>Exams </>
                          ) : isAmharicLang ? (
                            <>ፈተናዎች </>
                          ) : (
                            <>Qormaata </>
                          )}{" "}
                        </p>
                        <div className="flex mb-1">
                          <p className="text-left text-base  font-semibold">
                            {" "}
                            Award winning Exams{" "}
                          </p>

                          <p className="text-left text-sm  opacity-50 pl-1">
                            (Current month
                          </p>
                          <p className="text-left text-sm  opacity-50 font-semibold ">
                            - Week-2
                          </p>
                          <p className="text-left text-sm  opacity-50 ">)</p>
                        </div>
                        <div className="flex mb-4">
                          <p className="text-left text-sm  opacity-50 ">
                            Showcase your knowledge, and compete for top honors
                            and exciting prizes.
                          </p>
                        </div>

                        <div className="grid w-[100%] lg:grid-cols-2 gap-4  ">
                          {ExamData?.data?.data?.exams?.map((item, i) => {
                            return (
                              <div key={item}>
                                <div className=" rounded-lg shadow-lg py2 bg-white">
                                  <div className="  p-2  text-left  text-xl mb-[1%]">
                                    {" "}
                                    <div ClassName=" w-[20%] h-[100%]">
                                      {item.exam.file.link ? (
                                        <>
                                          <img
                                            className="object-cover h-60 w-full rounded-lg"
                                            src={item.exam.file.link}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <div className="flex  justify-center ">
                                            <img
                                              className=" h-60 w-fit rounded-lg"
                                              src={altExam}
                                            />
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex">
                                    <div className="w-[50%] flex flex-col ml-10 my-2">
                                      <p className="text-base font-bold w-[100%]  ">
                                        {" "}
                                        {isEnglishLang ? (
                                          <>
                                            <p className="">{item?.exam?.name}</p>
                                          </>
                                        ) : isAmharicLang ? (
                                          <>
                                            <p className="">{item?.exam?.name}</p>
                                          </>
                                        ) : (
                                          <>
                                            <p className="">{item?.exam?.name}</p>
                                          </>
                                        )}
                                      </p>
                                      <div className="flex justify-start opacity-50">
                                        <p className="mr-1">
                                          <BiTime />
                                        </p>
                                        <p className="text-xs flex text-right">
                                          {item.exam.total_time} minutes
                                        </p>
                                      </div>
                                    </div>
                                    <div className="flex justify-end w-[50%]">
                                      
                                      <button
                                        onClick={() => {
                                          setExamId(item.exam.id);

                                          window.scrollTo(0, 0);
                                          navigate(
                                            `/exam-info/${item.exam.id}`
                                          );
                                        }}
                                        className="border rounded-lg bg-[#f97316] m-4 text-base px-4 py-1  text-black  "
                                      >
                                        Take exam
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="   lg:w-[40%] max-h-[80%] flex  flex-col justify-start lg:pl-10 ">
                        <button
                          className="my-[10%] flex mr-[10%] bg-[#f97316]  bg-opacity-10 text-[#f97316]"
                          onClick={handleOpenRules}
                        >
                          <p className="mx-2 mt-1">
                            <AiOutlineInfoCircle />{" "}
                          </p>
                          Exam and awards instructions & guidelines.
                        </button>
                        <Modal
                          open={openRules}
                          onClose={handleCloseRules}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <div
                            className="absolute top-[5%] lg:top-[15%] max-h-[100vh] overflow-y-scroll left-[5%] lg:left-[25%] w-[90%]  
                          lg:w-[50%] bg-white border-2 border-solid border-black rounded-lg shadow-lg p-4"
                          >
                            <button
                              className="font-bold flex text-lg justify-end w-[100%] "
                              onClick={handleCloseRules}
                            >
                              x
                            </button>
                            <p className="mb-10 mx-10 text-sm ">
                              <p className="font-bold text-[#f97316] text-lg ">
                                Exam Rules and Instructions:
                              </p>
                              <ul class="list-disc pl-4 mb-4 ml-2">
                                <li>
                                  <b>Free Practice Exams:</b> Take these exams
                                  to assess your knowledge and practice without
                                  any time constraints.
                                </li>
                                <li>
                                  <b>Weekly Award Exams:</b> To be eligible for
                                  the weekly award, you must complete all the
                                  exams listed for the current week. The
                                  participant with the highest score will win
                                  exciting prizes such as a mobile phone,
                                  student bag, or mobile card airtime credit.
                                </li>
                                <li>
                                  {" "}
                                  <b>Monthly Award Exams:</b> To compete for the
                                  monthly award, you must complete all four
                                  weekly exams within the month and achieve the
                                  highest score. The grand prize for the monthly
                                  award is a brand new laptop.
                                </li>
                              </ul>
                              <b>
                                {" "}
                                Award Wining Exams: <br />1 - Weekly Award Exams{" "}
                              </b>
                              <ul class="list-disc pl-4 mb-4 ml-4 ">
                                <li>
                                  Complete all exams listed for the current week
                                  to be eligible for the weekly award.
                                </li>
                                <li>
                                  The participant with the highest score wins.{" "}
                                </li>
                                <li>
                                  {" "}
                                  Weekly prizes: Mobile phone, student bag, or
                                  mobile card airtime credit.
                                </li>
                              </ul>{" "}
                              <b>2 - Monthly Award Exams</b>
                              <ul class="list-disc pl-4 ml-4">
                                <li>
                                  {" "}
                                  Complete all four weekly exams within the
                                  month to compete for the monthly award.
                                </li>
                                <li>Achieve the highest score to win. </li>
                                <li> Grand prize: Brand new laptop.</li>
                              </ul>
                              <p className="font-bold text-[#f97316] text-sm mt-4">
                                Note: Review the specific deadlines and
                                requirements for each exam to ensure
                                eligibility. Good luck on your journey to
                                excellence!{" "}
                              </p>
                            </p>
                          </div>
                        </Modal>
                        <Winners />
                      </div>
                    </div>
                  </div>
                  <div className="flex w-[100%] my-6">
                    <div className=" flex flex-col w-[70%] lg:w-[90%]">
                      <p className="text-left text-xl mb-1 font-semibold w-[90%]">
                        {isEnglishLang ? (
                          <>Practice Exams </>
                        ) : isAmharicLang ? (
                          <>የልምምድ ፈተናዎች </>
                        ) : (
                          <>Qormaata Shaakala </>
                        )}{" "}
                      </p>
                      <p className="text-left text-sm  opacity-50 ">
                        These are free exams designed to assess your skills and
                        empower you for the upcoming contest.{" "}
                      </p>
                    </div>
                    <div className="flex flex-col lg:w-[12%] justify-end">
                      {" "}
                      <div className=" flex justify-end">
                        <button
                          className="flex  text-right lg:ml-[0%] border border-[#f97316]  text-sm rounded w-[100%] lg:w-[100%] text-black p-1 pr-4"
                          onClick={() => navigate("/exams-history")}
                        >
                          <div className="text-xl pr-1 text-[#f97316]">
                            <BiHistory />
                          </div>{" "}
                          {isEnglishLang ? (
                            <>Exam History </>
                          ) : isAmharicLang ? (
                            <>የፈተና ታሪክ </>
                          ) : (
                            <>Seenaa Qormaata </>
                          )}
                        </button>{" "}
                      </div>
                    </div>
                  </div>
                  <Exercises setExamId={setExamId} />
                </div>
              </div>
            </div>
          </>
        )}
        <div className="flex flex-col justify-center">
          <div className="flex justify-center mt-[3%]">
            <img src={giftBox} />
          </div>
          <div>
            <p className="lg:text-2xl flex justify-center font-bold mx-[3%]">
              Test your skills, Win exciting prizes! Join now for a rewarding
              learning experience!
            </p>
          </div>
          <div className="flex justify-center ">
            <div className="flex  flex-row  lg:w-[80%] mb-[8%] h-56">
              <Marquee>
                <img
                  className=" pb-[%]  pt-[%]  px-[15%] "
                  alt="vector2"
                  src={laptops}
                />

                <img
                  className=" pb-[%] pt-[25%]  px-[15%] "
                  alt="vector2"
                  src={phone}
                />
                <img
                  className=" pb-[%] pt-[25%]  px-[15%] "
                  alt="vector2"
                  src={backpack}
                />
                <img
                  className=" pb-[%] pt-[25%]  px-[15%]"
                  alt="vector2"
                  src={books}
                />
                <img
                  className=" pb-[%] pt-[25%]  px-[15%]"
                  alt="vector2"
                  src={laptop}
                />
                <img
                  className=" pb-[%] pt-[25%] px-[15%]"
                  alt="vector2"
                  src={mobileCard}
                />
                <img
                  className=" pb-[%]  pt-[5%]  px-[15%] "
                  alt="vector2"
                  src={laptops}
                />
                <img
                  className=" pb-[%] pt-[25%]  px-[15%] "
                  alt="vector2"
                  src={phone}
                />
                <img
                  className=" pb-[%] pt-[25%]  px-[15%] "
                  alt="vector2"
                  src={backpack}
                />
                <img
                  className=" pb-[%] pt-[25%]  px-[15%]"
                  alt="vector2"
                  src={books}
                />
                <img
                  className=" pb-[%] pt-[25%]  px-[15%]"
                  alt="vector2"
                  src={laptop}
                />
                <img
                  className=" pb-[%] pt-[25%] px-[15%]"
                  alt="vector2"
                  src={mobileCard}
                />
              </Marquee>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Exams;
